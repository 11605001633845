import React from 'react';
import PropTypes from 'prop-types';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirm } from 'modules/alert-confirm';
import { success, error } from 'components/shared/flash';
import DAYS from 'constants/ordinal-constants';

const ExportTemplateRow = ({
  exportTemplate,
  onExport,
  onEdit,
  onDelete,
}) => {

  const onDeleteExport = () => {
    confirm('Delete Bulk Form Export?', 'Are you sure you want to delete this Bulk Form Export?', () => {
      onDelete()
        .done(() => {
          success('Bulk Form Export deleted');
        })
        .fail(() => error());
    });
  };

  const onExportingExport = () => {
    onExport()
      .done(() => {
        success('Export queued. You will be emailed when your export completes');
      })
      .fail(() => error());
  };

  const renderForms = () => {
    return (
      <ul className='exporttemplate-exportrow'>
        {exportTemplate.items.map((i) => <li>{i.name}</li>)}
      </ul>
    );
  };

  const renderScheduledText = () => {
    return (
      <p className='margin-left nomargin-bottom inline-block italic'>- Scheduled to run on the {DAYS[exportTemplate.scheduled_day - 1]} of every month</p>
    )
  }

  return (
    <div className='margin-bottom-more'>
      <div className='exporttemplates-list-header'>
        <h2>{exportTemplate.name}</h2>
        {!!exportTemplate.scheduled_day && renderScheduledText()}
        <div className='exporttemplates-list-actions'>
          <FontAwesomeIcon
            icon={icon({ name: 'pencil' })}
            onClick={onEdit}
            title='Edit'
          />
          <FontAwesomeIcon
            icon={icon({ name: 'trash' })}
            onClick={onDeleteExport}
            title='Delete'
          />
        </div>
      </div>
      <div className="linebreak"></div>
      {renderForms()}
      <button className='btn-primary inline-block' onClick={onExportingExport}>Run Export</button>
    </div>
  );
};
ExportTemplateRow.propTypes = {
  exportTemplate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })),
  }).isRequired,
  onExport: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ExportTemplateRow;
