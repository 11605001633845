import React from "react";
import CollectInfoStepSummary from './shared/collect-info-step-summary';

const ProcessSummary= (props) => {
  const renderStepData = (step) => {
    return (
      <li key={step.id} className='review-stepinfo'>
        <div className='review-stepinfo-header'> {step.position}. {step.name}</div>
        <CollectInfoStepSummary step={step} collectedData={props.initiatedFlow.collected_data} />
      </li>
    );
  };

  return (
    <>
      {props.flowTemplate.steps.map(renderStepData)}
    </>
  )
}

export default ProcessSummary;