import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'components/shared/multi-select';
import Select from 'components/shared/select';
import { isEmpty, isNull } from 'underscore';
import { success, error } from 'components/shared/flash';
import SlidingToggle from 'components/shared/sliding-toggle';
import DAYS from 'constants/ordinal-constants';

const ExportTemplate = (props) => {
  const [exportTemplate, setExportTemplate] = useState(props.exportTemplate);
  const [scheduled, setScheduled] = useState(!!props.exportTemplate.scheduled_day);
  const [monthLimited, setMonthLimited] = useState(!!props.exportTemplate.prior_months_limit);
  const days = (Array.from(Array(28).keys())).map((index) => ({
    value: index + 1,
    label: DAYS[index],
  }));
  const options = useMemo(() => {
    if (!props.flowTemplates || !exportTemplate) { return []; }
    return props.flowTemplates.map((flowTemplate) => {
      const selected = exportTemplate.item_ids.includes(flowTemplate.id);
      return { value: flowTemplate.id, label: flowTemplate.name || 'Untitled form', selected };
    });
  }, [props.flowTemplates, exportTemplate]);

  const toggleScheduling = () => {
    if(scheduled) {
      onExportTemplateChange({ scheduled_day: null });
    }
    setScheduled(!scheduled);
  }

  const toggleMonthLimited = () => {
    if(monthLimited) {
      onExportTemplateChange({ prior_months_limit: null, prior_months_type: null });
    }
    setMonthLimited(!monthLimited);
  }

  const isNewExportTemplate = () => {
    return isNull(exportTemplate.id);
  };

  const onSave = () => {
    props.onSave(exportTemplate)
      .done((r) => {
        setExportTemplate(r);
        if (isNewExportTemplate()) {
          success('Bulk Form Export created');
        } else {
          success('Bulk Form Export updated');
          props.onCancel();
        }
      })
      .fail(() => error());
  };

  const onSelect = (ids) => {
    const dupTemplate = { ...exportTemplate };
    dupTemplate.item_ids = ids;
    setExportTemplate(dupTemplate);
  };

  const onExportTemplateChange = (change) => {
    setExportTemplate({ ...exportTemplate, ...change });
  };

  const renderButtons = () => {
    const disabled = isEmpty(exportTemplate.name) || isEmpty(exportTemplate.item_ids);
    return (
      <div>
        <button type='button' className='btn-primary margin-right' onClick={onSave} disabled={disabled}>Save</button>
        <button type='button' className='btn-secondary' onClick={props.onCancel}>Cancel</button>
      </div>
    );
  };

  const renderScheduledOption = () => {
    return (
      <div className='padding-left'>
        <label>
          Scheduled Day
          <Select
            options={days}
            disabled={!scheduled}
            value={days.find(({ value }) => value === exportTemplate.scheduled_day)}
            onChange={({ value }) => onExportTemplateChange({ scheduled_day: value })}
            styles={{
              container: (provided) => ({
                ...provided,
                display: 'inline-block',
                width: '300px',
              }),
            }}
          />
        </label>
      </div>
    );
  }

  const renderDateLimit = () => {
    const types = [{ value: 'launched', label: 'Launched' }, { value: 'completion', label: 'Completed' }];
    return (
      <div className='padding-left'>
        <label>
          Date Limit Type
          <Select
            options={types}
            disabled={!monthLimited}
            value={types.find(({ value }) => value === exportTemplate.prior_months_type)}
            onChange={({ value }) => onExportTemplateChange({ prior_months_type: value })}
            styles={{
              container: (provided) => ({
                ...provided,
                display: 'inline-block',
                width: '300px',
              }),
            }}
          />
        </label>
        <label htmlFor='month-limit'>
          Months Prior Limit
        </label>
          <input
            type='number'
            id='month-limit'
            disabled={!monthLimited}
            value={exportTemplate.prior_months_limit}
            onChange={(e) => onExportTemplateChange({ prior_months_limit: e.target.value })}
          />
           <p className='inline'> months ago</p>
      </div>
    )
  }

  const renderEditSettings = () => {
    return (
      <div className='exporttemplate'>
        <label className='border-bottom padding-bottom'>
          Name
          <input
            type='text'
            value={exportTemplate.name}
            id='exporttemplatename'
            placeholder='Bulk Form Export name'
            onChange={(e) => onExportTemplateChange({ name: e.target.value })}
            style={{ width: '300px' }}
          />
        </label>
        <div className='border-bottom padding-bottom' >
          <SlidingToggle
            onToggle={toggleScheduling}
            on={scheduled}
            label='Schedule Export'
            description='When on, export will automatically generate each month on day specified'
            className='margin-top margin-bottom'
          />
          {scheduled && renderScheduledOption()}
        </div>
        <div className='border-bottom padding-bottom' >
          <SlidingToggle
            onToggle={toggleMonthLimited}
            on={monthLimited}
            label='Limit Export by Months Prior'
            description='When on, export will only include submissions that were created or finished within the past specified number of months'
            className='margin-top margin-bottom'
          />
          {monthLimited && renderDateLimit()}
        </div>
        <div className='border-bottom padding-bottom' >
          <SlidingToggle
            onToggle={() => onExportTemplateChange({ include_legacy: !exportTemplate.include_legacy })}
            on={exportTemplate.include_legacy}
            label='Include Legacy Fields'
            description='When on, export will include legacy data from deleted fields'
            className='margin-top'
          />
        </div>
        <div className='padding-bottom padding-top'>
          <label>
            Forms
            <MultiSelect
              options={options}
              onSelect={onSelect}
            />
          </label>

        </div>
        {renderButtons()}
      </div>
    );
  };

  const title = isNewExportTemplate ? 'New Bulk Form Export' : <span>Editing bulk form export: <strong>{exportTemplate.name}</strong></span>;

  return (
    <div className='margin-bottom-more subprocessconfig-edit'>
      <h1>{title}</h1>
      {renderEditSettings()}
    </div>
  );
};

ExportTemplate.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  exportTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    item_ids: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  }),
  flowTemplates: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

ExportTemplate.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
};

export default ExportTemplate;
